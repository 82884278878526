import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthorizedGuard implements CanActivate {
  constructor(private service: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParams.t;
    return this.service.isAuthorized(token).pipe(
      tap(result => {
        if (!result) {
          this.redirectToLogin(route);
        }
      })
    );
  }

  private redirectToLogin(route: ActivatedRouteSnapshot) {
    location.assign(
      environment.IDENTIFICATION_API + '/login?r=' + encodeURI(location.href)
    );
  }
}
