import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { catchError } from 'rxjs/operators';
import { McuiModalService } from 'src/app/shared/services/mcui-modal.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authorization: AuthorizationService,
    private dlgService: McuiModalService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    const token = this.authorization.getAuthToken();
    request = request.clone({
      setHeaders: {
        // tslint:disable-next-line:max-line-length
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.dlgService.confirmError(
          error.status,
          error.statusText,
          error && error.error.messages ? error.error.messages.join(', ') : error.message
        ).then(_ => {});
        return throwError(error);
      })
    );
  }
}
