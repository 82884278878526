// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiEndPoint: '/api',
  IDENTIFICATION_API: 'https://identification.appdev.medocheck.com',
  googleClientId: '1098342833545-2kua9bu2f4j7npr2ucg8dljj4qjcb934.apps.googleusercontent.com',
  googleClientSecret: 'EJlekQ6ERQNp1TgQmN5-Sj5s'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
