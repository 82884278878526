import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileApiService } from '../http/user-profile-api.service';

@Injectable()
export class UserProfileResolver implements Resolve<any> {

  constructor(private userProfileApi: UserProfileApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.userProfileApi.get();
  }
}
