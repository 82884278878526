import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UUID } from 'angular2-uuid';

@Injectable()
export class IdentifiedRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method.toLocaleLowerCase() === 'get') {
      return next.handle(request);
    }

    const newRequest = request.clone({
      setHeaders: {
        'X-Request-Id': UUID.UUID()
      }
    });

    return next.handle(newRequest);
  }
}
