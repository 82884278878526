import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { globalization } from './assets/globalization/syncfusion-controls.js';
import { L10n, loadCldr } from '@syncfusion/ej2-base';

if (environment.production) {
  enableProdMode();
}

L10n.load(globalization);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
