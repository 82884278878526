import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Authorization } from '../models/authorization.model';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(
    private http: HttpClient,
    private localSt: LocalStorageService,
    private sessionSt: SessionStorageService
  ) {}

  refreshAuthorization(refreshToken: string): Observable<Authorization> {
    return this.http.post<Authorization>(
      environment.apiEndPoint + '/authorize/token',
      {
        token: refreshToken
      }
    );
  }

  getAuthToken(): string {
    const auth: Authorization = this.sessionSt.retrieve('mc-auth');
    if (auth === undefined || auth === null) {
      return undefined;
    }

    return auth.token;
  }

  isAdministrator(): Observable<boolean> {
    const auth: Authorization = this.sessionSt.retrieve('mc-auth');
    if (auth === undefined || auth === null) {
      return of(false);
    }

    if (
      auth.scopes.find(
        scope =>
          scope ===
          'http://onlineservices.medocheck.com/api/scopes/administratorUser'
      ) === undefined
    ) {
      return of(false);
    }

    return this.http
      .post<any>(environment.apiEndPoint + '/authorize/validatescopes', [
        {
          scope:
            'http://onlineservices.medocheck.com/api/scopes/administratorUser'
        }
      ])
      .pipe(
        map(result => {
          const validation = result.find(
            r =>
              r.scope ===
              'http://onlineservices.medocheck.com/api/scopes/administratorUser'
          );
          if (!validation || !validation.valid) {
            return false;
          }
          return true;
        }),
        catchError(error => {
          console.log(error);
          return of(false);
        })
      );
  }

  isAuthorized(refreshToken?: string): Observable<boolean> {
    if (!refreshToken) {
      // get auth from session storage
      const auth = this.sessionSt.retrieve('mc-auth');
      // auth exists and auth not expired
      if (auth !== undefined && auth !== null) {
        const expires = moment(auth.expires);
        const now = moment().utc();
        const expired = expires.isBefore(now);
        if (!expired) {
          // auth object is valid, return authorized
          return of(true);
        }
      }

      const remainSignedInToken = this.localSt.retrieve('mc-refresh-token');
      refreshToken = remainSignedInToken;
    }

    // if refresh token not exists, return not authorized
    if (refreshToken === undefined || refreshToken === null) {
      return of(false);
    }

    return this.refreshAuthorization(refreshToken).pipe(
      switchMap(newAuth => {
        if (!newAuth.valid) {
          return of(false);
        }

        // Save auth in storage
        this.sessionSt.store('mc-auth', newAuth);
        if (newAuth.remainSignedIn) {
          this.localSt.store('mc-refresh-token', newAuth.refreshToken);
        }
        return of(true);
      }),
      catchError((error: Error) => {
        console.log(error);
        // return not authorized
        alert(error.message);
        return of(false);
      })
    );
  }
}
