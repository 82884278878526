import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppModel } from '../models/app.model';
import { AccountModel } from '../models/account.model';

const endpoint = environment.apiEndPoint + '/admin';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  constructor(private http: HttpClient) {}

  get(): Observable<AccountModel[]> {
    return this.http.get<AccountModel[]>(`${endpoint}/accounts`);
  }

  getPending(): Observable<AccountModel[]> {
    return this.http.get<AccountModel[]>(`${endpoint}/accounts/pending`);
  }

  postAccountAction(action: string, id: string): Observable<any> {
    return this.http.post<any>(`${endpoint}/account/${id}/${action}`, {});
  }

  postChangeRole(id: string, role: number): Observable<any> {
    return this.http.post<any>(`${endpoint}/account/${id}/role`, {
      role: role
    });
  }
}
