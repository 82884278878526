import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { services } from './services';
import { apiServices } from './http';
import { IdentifiedRequestInterceptor } from './http/interceptors/id-request.interceptor';
import { JwtInterceptor } from './http/interceptors/jwt.interceptor';
import { guards } from './guards';
import * as moment from 'moment';
import { UIModule } from './ui/ui.module';
import { resolvers } from './resolvers';
import { SharedModule } from '../shared/shared.module';

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule, HttpClientModule, SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
    }) // ToastrModule added
  ], exports: []
})
export class CoreModule {

   // prevent for loading twice
   constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    moment.locale('de');
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ...services,
        ...apiServices,
        ...guards,
        ...resolvers,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: IdentifiedRequestInterceptor,
          multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
      ]
    };
  }
 }
