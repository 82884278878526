export const globalization = {
  de: {
      numerictextbox: {
          incrementTitle: 'Wert erhöhen',
          decrementTitle: 'Wert verringern',
      },
      colorpicker: {
          Apply: 'Ok',
          Cancel: 'Abbrechen',
          ModeSwitcher: 'Modus wechseln',
      },
      dropdowns: {
          noRecordsTemplate: 'Keine Vorschläge gefunden',
          actionFailureTemplate: 'Ups, etwas ist schief gegangen...',
      },
      datetimepicker: {
          placeholder: 'Wählen Sie ein Datum und eine Uhrzeit aus',
          today: 'heute',
      },
      dialog: {
          close: 'Schliessen',
          ok: 'Ok',
          cancel: 'Abbrechen',
          apply: 'Übernehmen',
      },
      grid: {
          EmptyRecord: 'Die Datenliste ist aktuell leer.',
          GroupDropArea:
              'Ziehen Sie einen Spaltenkopf hier, um die Gruppe ihre Spalte',
          UnGroup: 'Klicken Sie hier, um die Gruppierung aufheben',
          EmptyDataSourceError:
              'DataSource darf bei der Erstauslastung nicht leer sein, da Spalten aus der dataSource im AutoGenerate Spaltenraster',
          Item: 'Eintrag',
          Items: 'Einträge',
          Search: 'Suche',
      },
      pager: {
          currentPageInfo: '{0} von {1} Seiten',
          totalItemsInfo: '({0} Einträge)',
          firstPageTooltip: 'Zur ersten Seite',
          lastPageTooltip: 'Zur letzten Seite',
          nextPageTooltip: 'Zur nächsten Seite',
          previousPageTooltip: 'Zurück zur letzten Seite',
          nextPagerTooltip: 'Zum nächsten Pager',
          previousPagerTooltip: 'Zum vorherigen Pager',
          pagerDropDown: 'Einträge',
          pagerAllDropDown: 'Einträge',
          All: 'alle'
      },
      schedule: {
          day: 'Tag',
          week: 'Woche',
          workWeek: 'Arbeitswoche',
          month: 'Monat',
          agenda: 'Liste',
          weekAgenda: 'Wochenliste',
          workWeekAgenda: 'Arbeitswochenliste',
          monthAgenda: 'Monatsliste',
          today: 'Heute',
          noEvents: 'Keine Kurstermine',
          emptyContainer: 'Für heute gibt es keine Kurstermine',
          allDay: 'Ganztags',
          start: 'Start',
          end: 'Ende',
          more: 'mehr',
          close: 'Schließen',
          cancel: 'Abbrechen',
          noTitle: '(Ohne Titel)',
          delete: 'Löschen',
          deleteEvent: 'Kurstermin löschen',
          deleteMultipleEvent: 'Mehrere Kurstermine löschen',
          selectedItems: 'Kurstermin ausgewählt',
          deleteSeries: 'Kursserie löschen',
          edit: 'Bearbeiten',
          editSeries: 'Kursserie',
          editEvent: 'Kurstermin',
          createEvent: 'Erstellen',
          subject: 'Betreff',
          addTitle: 'Titel hinzufügen',
          moreDetails: 'Mehr Details',
          save: 'Speichern',
          editContent:
              'Möchten Sie nur diesen Kurstermin oder die ganze Kursserie bearbeiten?',
          deleteRecurrenceContent:
              'Möchten Sie nur diesen Kurstermin oder die ganze Kursserie löschen?',
          deleteContent:
              'Sind Sie sicher, dass Sie diesen Kurstermin löschen möchten?',
          deleteMultipleContent:
              'Sind Sie sicher, dass Sie die ausgewählten Kurstermine löschen möchten?',
          newEvent: 'Neuer Kurstermin',
          title: 'Titel',
          location: 'Ort',
          description: 'Beschreibung',
          timezone: 'Zeitzone',
          startTimezone: 'Start Zeitzone',
          endTimezone: 'Ende Zeitzone',
          repeat: 'Wiederholen',
          saveButton: 'Speichern',
          cancelButton: 'Abbrechen',
          deleteButton: 'Löschen',
          recurrence: 'Wiederholung',
          wrongPattern: 'Der Wiederholungsausdruck ist nicht gültig.',
          seriesChangeAlert:
              'Die Änderungen an den Instanzen der Kursserie werden zurückgesetzt und die Kurstermine werden wieder in die Serie eingeliedert.',
          createError:
              'Die Dauer des Termins muss kleinder als das Wiederholungsintervall sein.',
          recurrenceDateValidation:
              'Einige Monate haben weniger Tage als das ausgewählte Datum. Für diese Monate wird die Wiederholung jeweils am letzten Tag des Monats angezeigt.',
          sameDayAlert:
              'Zwei Vorkommen des gleichen Kurstermines an einem Tag ist ungültig.',
          editRecurrence: 'Kursserie bearbeiten',
          repeats: 'Wiederholungen',
          alert: 'Achtung',
          startEndError: 'Das ausgewählte Ende muss vor dem Start liegen.',
          invalidDateError: 'Ihre Eingabe ist kein gültiges Datum.',
          ok: 'Ok',
          occurrence: 'Vorkommen',
          series: 'Serie',
          previous: 'Zurück',
          next: 'Weiter',
          timelineDay: 'Zeitlinie Tag',
          timelineWeek: 'Zeitlinie Woche',
          timelineWorkWeek: 'Zeitlinie Arbeitswoche',
          timelineMonth: 'Zeitlinie Monat',
      },
      recurrenceeditor: {
          none: 'Keine',
          daily: 'Täglich',
          weekly: 'Wöchentlich',
          monthly: 'Monatlich',
          month: 'Monat',
          yearly: 'Jährlich',
          never: 'Niemals',
          until: 'Bis',
          count: 'Anzahl',
          first: 'Ersten',
          second: 'Zweiten',
          third: 'Dritten',
          fourth: 'Vierten',
          last: 'Letzten',
          repeat: 'Wiederhole',
          repeatEvery: 'Wiederhole alle',
          on: 'Wiederhole am',
          end: 'Ende',
          onDay: 'Tag',
          days: 'Tag(e)',
          weeks: 'Woche(n)',
          months: 'Monat(e)',
          years: 'Jahr(e)',
          every: 'jeden',
          summaryTimes: 'mal',
          summaryOn: 'am',
          summaryUntil: 'bis',
          summaryRepeat: 'Wiederholungen',
          summaryDay: 'Tage(e)',
          summaryWeek: 'Woche(n)',
          summaryMonth: 'Monat(e)',
          summaryYear: 'Jahr(e)',
      },
  },
};
