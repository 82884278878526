import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { McuiModalService } from './services/mcui-modal.service';
import { SecureImageComponent } from './components/secure-image/secure-image.component';
import { MomentModule } from 'ngx-moment';
import { UIModule } from '../core/ui/ui.module';
import { SidebarToggleButtonComponent } from './components/sidebar-toggle-button/sidebar-toggle-button.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
@NgModule({
  declarations: [ConfirmationDialogComponent, SecureImageComponent, SidebarToggleButtonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MomentModule,
    UIModule,
    MomentModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SecureImageComponent,
    SidebarToggleButtonComponent,
    MomentModule,
    ButtonModule,
    UIModule,
    MomentModule
  ],
  providers: [ McuiModalService ],
  entryComponents: [ConfirmationDialogComponent]
})
export class SharedModule { }
