import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppModel } from '../models/app.model';

const endpoint = environment.apiEndPoint + '/apps';

@Injectable({
  providedIn: 'root'
})
export class AppApiService {
  constructor(private http: HttpClient) {}

  get(): Observable<AppModel[]> {
    return this.http.get<AppModel[]>(endpoint);
  }

  post(domain: string, appname: string): Observable<AppModel> {
    return this.http.post<AppModel>(endpoint, {
      domain: domain,
      applicationName: appname
    });
  }

  authorize(id: string): Observable<AppModel> {
    return this.http.post<AppModel>(`${endpoint}/${id}/authorize`, {});
  }

  revoke(id: string): Observable<any> {
    return this.http.delete(`${endpoint}/${id}`);
  }
}
