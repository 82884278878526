import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InviteUserModel } from '../models/invite-user.model';

const endpoint = environment.apiEndPoint + '/admin';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private http: HttpClient) { }

  inviteUsers(model: InviteUserModel) {
      return this.http.post(`${endpoint}/invite`, model);
  }

  refreshInvite(id) {
    return this.http.post(`${endpoint}/invite/${id}/refresh`, {});
}
}
