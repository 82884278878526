import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class IsAdministratorGuard implements CanActivate {
  constructor(private service: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.service.isAdministrator();
  }
}
