import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, map, catchError, finalize, distinct, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mcui-secure-image',
  templateUrl: './secure-image.component.html',
  styleUrls: ['./secure-image.component.scss']
})
export class SecureImageComponent implements OnInit, OnChanges {
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {}

  loading = false;

  @Input() private src: string;
  @Input() alt = '';
    @Input() width = '100%';
    @Input() radius = '0';

  private src$ = new BehaviorSubject(this.src);

  dataUrl$ = this.src$.pipe(
    distinctUntilChanged(),
    switchMap(url => this.loadImage(url)));

  ngOnInit() {}
  ngOnChanges(): void {
    this.reload();
  }

  reload() {
    const ts = new Date().getTime();
    this.src$.next(this.src + '?ts=' + ts);
  }

  private loadImage(url: string): Observable<any> {
    setTimeout(() => {
      this.loading = true;
    });
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(e =>
        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))
      ),
      tap(e => {
        this.loading = false;
      })
    );
  }
}
