import { Injectable } from '@angular/core';
import { AccountApiService } from '../http/account-api.service';
import { Observable } from 'rxjs';
import { AccountModel } from '../models/account.model';
import { UserApiService } from '../http/user-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private accountApi: AccountApiService, private userApi: UserApiService) { }

  getAccounts(): Observable<AccountModel[]> {
    return this.accountApi.get();
  }

  getPendingAccounts(): Observable<AccountModel[]> {
    return this.accountApi.getPending();
  }

  invokeAccount(id: string): Observable<any> {
    return this.accountApi.postAccountAction('invoke', id);
  }

  revokeAccount(id: string): Observable<any> {
    return this.accountApi.postAccountAction('revoke', id);
  }

  deleteAccount(id: string): Observable<any> {
    return this.accountApi.postAccountAction('delete', id);
  }

  refreshInviteAccount(id: string): Observable<any> {
    return this.userApi.refreshInvite(id);
  }

  changeRole(id: string, role: number): Observable<any> {
    return this.accountApi.postChangeRole(id, role);
  }
}
