import {
  Injectable,
  ViewContainerRef,
  ComponentFactoryResolver,
  ApplicationRef
} from '@angular/core';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

export interface ConfirmationDialog {
  title?: string;
  text?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  showOkButton;
}

export interface ConfirmationModal extends ConfirmationDialog {
  destroy$?: Subject<boolean>;
  open?: boolean;
}

const defaultConfirmationDialog: ConfirmationDialog = {
  title: 'Untitled',
  text: 'Nothing to say',
  okButtonText: 'Ok',
  cancelButtonText: 'Cancel',
  showOkButton: true
};

@Injectable({
  providedIn: 'root'
})
export class McuiModalService {
  vcr: ViewContainerRef;
  constructor(
    private cfr: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  setViewContainerRef(vcr: ViewContainerRef) {
    this.vcr = vcr;
  }

  confirm(options?: ConfirmationDialog) {
    if (!this.vcr) {
      this.vcr = (this.appRef.components[0]
        .instance as AppComponent).viewContainerRef;
    }

    const opt = options
      ? { ...defaultConfirmationDialog, ...options }
      : defaultConfirmationDialog;
    console.log(this.cfr);

    const factory = this.cfr.resolveComponentFactory(ConfirmationDialogComponent);
    const ref = factory.create(this.vcr.parentInjector);
    const instance = Object.assign(<ConfirmationModal>ref.instance, opt);
    setTimeout(() => this.vcr.insert(ref.hostView));

    return instance.destroy$
      .asObservable()
      .pipe(
        take(1),
        tap(() => ref.destroy())
      )
      .toPromise();
  }

  confirmDiscardUnsavedChanges(
    options: ConfirmationDialog = {
      title: 'Diese Seite hat ungespeicherte Änderungen!',
      text: 'Möchten Sie die Seite verlassen und die Änderungen verwerfen?',
      okButtonText: 'Seite verlassen',
      cancelButtonText: 'Abbrechen',
      showOkButton: true
    }
  ) {
    return this.confirm(options);
  }

  confirmError(status, statusDescription, message) {
    return this.confirm({
      title: 'Fehler ' + status + ' (' + statusDescription + ')',
      text: message,
      okButtonText: '',
      cancelButtonText: 'Ok',
      showOkButton: false
    });
  }
}
