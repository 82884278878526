import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/userprofile.model';
import { LicenseInfo } from '../models/userinfo.model';
import { LoginLogModel } from '../models/login-log.model';
import { InviteUserModel } from '../models/invite-user.model';

const endpoint = environment.apiEndPoint + '/userprofile';

@Injectable({
  providedIn: 'root'
})
export class UserProfileApiService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(endpoint);
  }

  getLicenses() {
    return this.http.get<LicenseInfo[]>(`${endpoint}/licenses`);
  }

  getLogins() {
    return this.http.get<LoginLogModel[]>(`${endpoint}/logins`);
  }

  deleteLogins(useragent: string) {
    return this.http.delete(`${endpoint}/logins?q=${encodeURI(useragent)}`);
  }

  update(profile: UserProfile) {
    return this.http.post(endpoint, profile);
  }

  requestChangeEmail(email: string) {
    return this.http.post(`${endpoint}/email`, { email });
  }

  requestChangePassword() {
    return this.http.post(`${endpoint}/password`, {});
  }

  cancelChangeEmail(email: string) {
    return this.http.post(`${endpoint}/email/cancel`, { email });
  }

  uploadProfileImage(id, formdata) {

    const data = this.toFormData(formdata.files);

    return this.http.post(
      `${endpoint}/image`,
      data
    );
  }

  private toFormData(files) {
    const formData = new FormData();
    const fileToUpload = files[0];
    formData.append('file', fileToUpload, fileToUpload.name);
    return formData;
  }
}
