import { Component, OnInit, Input } from '@angular/core';
import { Sidebar } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'mcui-sidebar-toggle-button',
  templateUrl: './sidebar-toggle-button.component.html',
  styleUrls: ['./sidebar-toggle-button.component.scss']
})
export class SidebarToggleButtonComponent implements OnInit {

  @Input() sidebar: Sidebar;

  constructor() { }

  ngOnInit() {
  }

  onToggleSidebarClicked() {
    this.sidebar.toggle();
  }
}
