import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule, ClarityModule, RouterModule
  ],
  exports: [
    LayoutComponent, ClarityModule
  ]
})
export class UIModule { }
