import { Injectable } from '@angular/core';
import { AppApiService } from '../http/app-api.service';
import { Observable } from 'rxjs';
import { AppModel } from '../models/app.model';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  constructor(private api: AppApiService) {}

  createNewApp(domain: string, appName: string): Observable<AppModel> {
    return this.api.post(domain, appName);
  }

  createMedoCheckApp(): Observable<AppModel> {
    return this.createNewApp('medocheck.com', 'medo.check Software');
  }

  loadApps(): Observable<AppModel[]> {
    return this.api.get();
  }

  authorizeApp(app: AppModel): Observable<AppModel> {
    return this.api.authorize(app.id);
  }

  revokeApp(app: AppModel): Observable<AppModel> {
    return this.api.revoke(app.id);
  }
}
