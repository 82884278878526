import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizedGuard } from './core/guards/authorized.guard';

const routes: Routes = [
  {
    path:  '',
    pathMatch: 'full',
    redirectTo: 'userprofile'
  },
  {
    canActivate: [AuthorizedGuard],
    path: 'userprofile',
    loadChildren: () => import('src/app/modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    canActivate: [AuthorizedGuard],
    path: 'adminprofile',
    loadChildren: () => import('src/app/modules/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
