import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mcui-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @Input() headerEnabled = true;

  constructor() {}

  ngOnInit() {}
}
