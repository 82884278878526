import { Component, Inject, LOCALE_ID, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'mcui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MCUsermanagementService';

  constructor(
    public viewContainerRef: ViewContainerRef,
    @Inject(LOCALE_ID) locale: string
  ) {
    const code = locale.substr(0, 2);
    moment.locale(code);
  }
}
