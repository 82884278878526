import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationModal } from '../../services/mcui-modal.service';

@Component({
  selector: 'mcui-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, ConfirmationModal {
  destroy$: Subject<boolean> = new Subject();

  open = true;
  title = 'Untitled';
  text = 'Noting to say...';
  okButtonText = 'Ok';
  cancelButtonText = 'Cancel';
  showOkButton = false;

  constructor() {}

  ngOnInit() {}

  clicked(result) {
    this.open = false;
    this.destroy$.next(result);
  }
}
